import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './DataGrid.css';

const DataGrid = () => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await axios('http://4.236.125.88:5000/data');
                //const result = await axios(process.env.DATA_API_URL);
                setData(result.data);
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setData([]);  // You might want to handle errors differently
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, 20000); // Set up the timer

        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <h1>MQTT Messages</h1>
            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Time</th>
                        <th>Topic</th>
                        <th>Message</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.MessageID}>
                            <td>{item.MessageID}</td>
                            <td>{item.TimeStamp}</td>
                            <td>{item.Topic}</td>
                            <td>{item.Message}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DataGrid;

